import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { Intro } from '../../../components/Intro/Intro';
import Connect from '../../../components/Connect';
import { StyledLink, OutboundLink } from '../../../components/StyledLink';
import Projects from '../../../components/Projects';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { RandomizerAbout } from '../../../components/RandomizerAbout';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { designTokens } from '../../../components/Theme/designTokens';
import { ButtonLink, ButtonOutbound, ButtonOutboundGhost } from '../../../components/Button';
import { CardContainer, CardBody } from '../../../components/Card';
import resumePDF from '../../../static/RyanParag-resume.pdf';
import { Badge } from '../../../components/Badge';
import { Pill } from '../../../components/Pill';
import { List, ListItem, ListInner } from '../../../components/List';
import MasoniteIcon from '../../../static/masonite-small-icon.svg';
import CB911Icon from '../../../static/cb911-small-icon.svg';
import RyanIcon from '../../../static/ryanparag-small-icon.svg';
import XcendaIcon from '../../../static/xcenda-small-icon.svg';
import AmericaIcon from '../../../static/language-en.svg';
import IndiaIcon from '../../../static/language-in.svg';
import SpainIcon from '../../../static/language-es.svg';
import FranceIcon from '../../../static/language-fr.svg';
import JapanIcon from '../../../static/language-jp.svg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Resume" keywords={['portfolio', 'Ryan Parag', 'designer', 'UI/UX', 'product designer']} mdxType="SEO" />
    <div style={{
      height: designTokens.space[9]
    }}></div>
    <Container mdxType="Container">
  <CSSGrid mdxType="CSSGrid">
    <Col col={2 / 3} media={{
          phone: 1
        }} mdxType="Col">
      <div>
        <StyledLink underline="true" to={'/about'} mdxType="StyledLink">About</StyledLink>{' '}{' '}/{' '}{' '}<strong>Resumé</strong>
      </div>
      <h4>Hello, I'm Ryan. I'm a multidisciplinary designer and problem solver.</h4>
      <p>I’m driven to learn why people do what they do so I can create experiences that are intuitive and successful. I’ve helped rethink, prototype and design solutions to help bring empathy and simplicty into complex problems.</p>
      <p>Currently, I am a Product Designer at Masonite where I help simplify the home remodeling experience for contractors and homeowners with the Advisar platform, as well as help build the IoT experience for homeowners that want to incorporate a connected door to their smart home.</p>
      <p>Previously at Chargebacks911, I helped design solutions across multiple product teams and ship multiple enterprise payments applications from concept to production.</p>
      <p>I help bring an understanding of designing successful experiences, with research, marketing, engineers, PMs and directors.</p>
      <ButtonOutboundGhost href="mailto:parag.ryan@gmail.com?subject: Hey Ryan!" target="_blank" color={({
            theme
          }) => theme.highlightedText} marginRight={designTokens.space[3]} marginBottom={designTokens.space[3]} mdxType="ButtonOutboundGhost">parag.ryan@gmail.com</ButtonOutboundGhost>
      <ButtonOutboundGhost href="tel:19417356602" target="_blank" color={({
            theme
          }) => theme.tertiaryDark} marginRight={designTokens.space[3]} marginBottom={designTokens.space[3]} mdxType="ButtonOutboundGhost">941.735.6602</ButtonOutboundGhost>
      <ButtonOutboundGhost href="https://ryanparag.com" target="_blank" color={({
            theme
          }) => theme.visited} marginBottom={designTokens.space[3]} mdxType="ButtonOutboundGhost">ryanparag.com</ButtonOutboundGhost>
      <div style={{
            height: designTokens.space[4]
          }}></div>
    </Col>
    <Col col={1 / 3} media={{
          phone: 1
        }} mdxType="Col">
      <Text size={designTokens.fontSizes[0]} marginTop={designTokens.space[6]} marginBottom={designTokens.space[3]} color={({
            theme
          }) => theme.grey500} mdxType="Text">
        View my resumé on this page or download a PDF version using the button below:
      </Text>
      <ButtonOutbound shadow href={resumePDF} marginBottom={designTokens.space[3]} block bg={({
            theme
          }) => theme.green} mdxType="ButtonOutbound">
        Download PDF
      </ButtonOutbound>
      <Connect mdxType="Connect" />
      <div style={{
            height: designTokens.space[4]
          }}></div>
    </Col>
  </CSSGrid>
  <CSSGrid mdxType="CSSGrid">
    <Col mdxType="Col">
      <h5>Professional Experience</h5>
      <Badge marginBottom={designTokens.space[3]} mdxType="Badge">
        <img src={MasoniteIcon} width="20" style={{
              marginRight: designTokens.space[2]
            }} />
        <strong>Masonite</strong>
      </Badge>
      <Text marginBottom={designTokens.space[2]} mdxType="Text"><strong>Product Designer</strong> (March 2019 - Present)</Text>
      <p>Working on multiple product experiences for the Advisar platform, which helps flatten and simplify the home remodeling experience for homeowners and contractors in new and emerging markets.</p>
      <p>I also help build the IoT experience that helps homeowners connect a Masonite door system to their smart home through our cutting-edge cloud platform.</p>
      <Badge marginBottom={designTokens.space[3]} mdxType="Badge">
        <img src={CB911Icon} width="20" style={{
              marginRight: designTokens.space[2]
            }} />
        <strong>Chargebacks911</strong>
      </Badge>
      <Text marginBottom={designTokens.space[2]} mdxType="Text"><strong>Product Designer</strong> (March 2016 - March 2019)</Text>
      <p>Lead designer working between multiple engineering, executive, and marketing teams. Led the design of two legacy applications and one new application for clients in the enterprise payments space. Worked on UX flows, design systems, rapid prototyping, user journeys, marketing assets, and live product. Increased qualified web traffic by 400% and inbound leads by 250%.</p>
      <Badge marginBottom={designTokens.space[3]} mdxType="Badge">
        <img src={RyanIcon} width="20" style={{
              marginRight: designTokens.space[2]
            }} />
        <strong>GrapaLab</strong>
      </Badge>
      <Text marginBottom={designTokens.space[2]} mdxType="Text"><strong>Freelance</strong> (2009 - Present)</Text>
      <p>Managed product and branding projects for clients like World Rugby, the governing body of rugby, LinkMe, a meeting app startup, and Patellini's, an Italian restaurant chain, Soleventure, and host of other clients</p>
      <Badge marginBottom={designTokens.space[3]} mdxType="Badge">
        <img src={XcendaIcon} width="20" style={{
              marginRight: designTokens.space[2]
            }} />
        <strong>Xcenda</strong>
      </Badge>
      <Text marginBottom={designTokens.space[2]} mdxType="Text"><strong>Healthcare Analyst</strong> (June 2013 - May 2016)</Text>
      <p>Analyzed data to measure pharmaceutical outcomes, investigated key business problems through quantitative analyses of pharmaceutical cost data. Helped forecast business decisions against market outcomes and parent company (AmerisourceBergen) goals. Verfied report data in relation to population demographics, cost, and insurance.</p>
      <div style={{
            height: designTokens.space[4]
          }}></div>
    </Col>
    <Col col={1 / 2} media={{
          phone: 1
        }} mdxType="Col">
      <h5>Skills</h5>
      <CardContainer mdxType="CardContainer">
        <CardBody paddingX={'0'} paddingY={'0'} mdxType="CardBody">
          <List mdxType="List">
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Interface Design
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Interaction Design
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Prototyping
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Visual Design
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                UX, Ideation, and Workflows
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                UI design
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Branding
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                User Research
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Usability Testing
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Communication Design
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Front-end Development
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Product Strategy
              </ListInner>
            </ListItem>
          </List>
        </CardBody>
      </CardContainer>
    </Col>
    <Col col={1 / 2} media={{
          phone: 1
        }} mdxType="Col">
      <h5>Tools</h5>
      <CardContainer mdxType="CardContainer">
        <CardBody paddingX={'0'} paddingY={'0'} mdxType="CardBody">
          <List mdxType="List">
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Sketch
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Figma
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Git
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                HTML/CSS/JS
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                React / Gatsby / Next
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Node & Firebase
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Airtable
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Adobe Creative Suite
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Principle
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Origami
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                Framer
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                SQL/R/Python
              </ListInner>
            </ListItem>
            <ListItem border mdxType="ListItem">
              <ListInner dense mdxType="ListInner">
                CMS Platforms (WordPress, Webflow, etc.)
              </ListInner>
            </ListItem>
          </List>
        </CardBody>
      </CardContainer>
    </Col>
    <Col mdxType="Col">
      <div style={{
            height: designTokens.space[4]
          }}></div>
      <h5>Design Approach</h5>
      <p><strong>Small Details vs. Business Needs.</strong> I like to envision products from end-to-end — from the smallest interaction to the design system it sits in, and how it impacts the larger business strategy.</p>
      <p><strong>Collaboration</strong> I like connecting at the individual level and across silos, bubbling up different points of view. I think having a shared vision helps design a product that everyone is excited to build.</p>
      <p><strong>T-shaped</strong> I don't think designers are defined by the tools we use — I think our ability to be flexible in the tools we use and how we look at unique problems characterize a product designer. I think our toolbox should be defined by the problems-at-hand and how we can work with our surrounding teams.</p>
    </Col>
    <Col col={1 / 2} media={{
          phone: 1
        }} mdxType="Col">
      <div style={{
            height: designTokens.space[4]
          }}></div>
      <h5>Education</h5>
      <Text marginBottom={designTokens.space[2]} color={({
            theme
          }) => theme.highlightedText} mdxType="Text">
        <strong>University of South Florida</strong>
      </Text>
      <Text marginBottom={designTokens.space[4]} mdxType="Text"><strong>M.S. Certificate - Applied Biostatistics</strong> (2015 - 2016)</Text>
      <Text marginBottom={designTokens.space[2]} color={({
            theme
          }) => theme.highlightedText} mdxType="Text">
        <strong>University of Central Florida</strong>
      </Text>
      <Text marginBottom={designTokens.space[4]} mdxType="Text"><strong>B.S. Biology, B.S. Health Informatics</strong> (2008 - 2012)</Text>
    </Col>
    <Col col={1 / 2} media={{
          phone: 1
        }} mdxType="Col">
      <div style={{
            height: designTokens.space[4]
          }}></div>
      <h5>Certifications</h5>
      <Text marginBottom={designTokens.space[2]} color={({
            theme
          }) => theme.highlightedText} mdxType="Text">
        <strong>IBM</strong>
      </Text>
      <Text marginBottom={designTokens.space[4]} mdxType="Text"><strong>Enterprise Design Thinking</strong> (2020)</Text>
      <Text marginBottom={designTokens.space[2]} color={({
            theme
          }) => theme.highlightedText} mdxType="Text">
        <strong>IDEO</strong>
      </Text>
      <Text marginBottom={designTokens.space[4]} mdxType="Text"><strong>Foundations in Design Thinking</strong></Text>
    </Col>
    <Col col={1 / 2} media={{
          phone: 1
        }} mdxType="Col">
      <div style={{
            height: designTokens.space[4]
          }}></div>
      <h5>Languages</h5>
      <p>
          In case you were wondering, I'm an <i>aspiring</i> polyglot and am trying to level up my language skills daily!
      </p>
      <Badge title="Hey - I'm Ryan!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={AmericaIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>English</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          C2 (Proficient)
        </Pill>
      </Badge>
      <br />
      <Badge title="Namaste - maaru naam Ryan chhe!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={IndiaIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>Gujarati</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          C1 (Advanced)
        </Pill>
      </Badge>
      <br />
      <Badge title="Namaste - mera naam Ryan hai!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={IndiaIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>Hindi</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          B1 (Intermediate)
        </Pill>
      </Badge>
      <br />
      <Badge title="Hola - soy Ryan!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={SpainIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>Spanish</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          A2 (Elementary)
        </Pill>
      </Badge>
      <br />
      <Badge title="Bonjour - je m​'appelle Ryan!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={FranceIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>French</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          A1 (Beginner)
        </Pill>
      </Badge>
      <br />
      <Badge title="Koncha - watashi wa sumisu Ryan desu!" style={{
            marginBottom: designTokens.space[3]
          }} mdxType="Badge">
        <img src={JapanIcon} width="24" style={{
              marginRight: designTokens.space[2]
            }} />
        <small>
          <strong>Japanese</strong>
        </small>
        <Pill marginLeft={designTokens.space[2]} color={({
              theme
            }) => theme.grey500} mdxType="Pill">
          Just starting!
        </Pill>
      </Badge>
    </Col>
  </CSSGrid>
    </Container>
    <div style={{
      height: designTokens.space[8]
    }}></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      